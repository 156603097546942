import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/layout/models/users.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
  ) {

  }

  /**
   * Get all records
   * @returns {Observable}
   */
  singin(username: string, password: string): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}sigin`, {
      username: username,
      password: password,
    });
  }
  /**
   * 
   */
  login(username: string, password: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.singin(username, password).subscribe({
        next: (user) => {
          this.setLocalStorageSession(user);
          observer.next(true);
          observer.complete();
        },
        error: (error) => {
          console.log("session_start error %o", error);
          observer.error('Error de inicio de sesión');
        }
      });
    });
  }
  /**
   * 
   */
  logout(): Observable<boolean> {
    this.deleteLocalStorageSession();
    return of(true);
  }
  /**
   * Set local storage session
   * @param authResponse 
   */
  setLocalStorageSession(userData: User): Observable<boolean> {
    localStorage.setItem('code', userData.code);
    localStorage.setItem('id', userData.id.toString());
    localStorage.setItem('organizations_id', userData.organizations_id.toString());
    localStorage.setItem('colleges_id', userData.colleges_id.toString());
    localStorage.setItem('parent_id', userData.parent_id.toString());
    localStorage.setItem('roles_id', userData.roles_id.toString());
    localStorage.setItem('codeType', userData.codeType.toString());
    localStorage.setItem('gender', userData.gender);
    localStorage.setItem('fname', userData.fname);
    localStorage.setItem('lname', userData.lname);
    localStorage.setItem('active', userData.active ? 't' : 'f');
    localStorage.setItem('delete', userData.delete ? 't' : 'f');
    localStorage.setItem('address', userData.address);
    localStorage.setItem('email', userData.email);
    localStorage.setItem('phone', userData.phone);
    localStorage.setItem('avatar', userData.avatar);
    localStorage.setItem('info', userData.info);
    localStorage.setItem('nicename', `${userData.fname} ${userData.lname}`);
    return of(true);
  }
  /**
   * Remove local storage session
   * @returns 
   */
  deleteLocalStorageSession(): Observable<boolean> {
    localStorage.removeItem('code');
    localStorage.removeItem('id');
    localStorage.removeItem('organizations_id');
    localStorage.removeItem('colleges_id');
    localStorage.removeItem('parent_id');
    localStorage.removeItem('roles_id');
    localStorage.removeItem('codeType');
    localStorage.removeItem('gender');
    localStorage.removeItem('fname');
    localStorage.removeItem('lname');
    localStorage.removeItem('active');
    localStorage.removeItem('delete');
    localStorage.removeItem('address');
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
    localStorage.removeItem('avatar');
    localStorage.removeItem('info');
    localStorage.removeItem('nicename');
    return of(true);
  }
  /**
   * Get local storage session data
   * @returns 
   */
  getLocalStorageSession(): User | null {
    if (!localStorage.getItem('id')) return null;
    return {
      id: +localStorage.getItem('id'),
      code: localStorage.getItem('code'),
      organizations_id: +localStorage.getItem('organizations_id'),
      colleges_id: +localStorage.getItem('colleges_id'),
      parent_id: +localStorage.getItem('parent_id'),
      roles_id: +localStorage.getItem('roles_id'),
      codeType: +localStorage.getItem('codeType'),
      gender: localStorage.getItem('gender'),
      fname: localStorage.getItem('fname'),
      lname: localStorage.getItem('lname'),
      active: localStorage.getItem('active') === 't',
      delete: localStorage.getItem('delete') === 't',
      address: localStorage.getItem('address'),
      email: localStorage.getItem('email'),
      phone: localStorage.getItem('phone'),
      avatar: localStorage.getItem('avatar'),
      info: localStorage.getItem('info'),
      nicename: localStorage.getItem('nicename'),
    };
  }
  /**
   * 
   */
  get is_session_start(): boolean {
    return localStorage.getItem('id') ? true : false;
  }
}
