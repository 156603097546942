<div class="container h-100">
    <div
        class="d-flex h-100 flex-column text-center justify-content-center align-content-center align-items-center h-100 py-3">
        <h1>404</h1>
        <p>
            Parece que no has encontrado lo que estabas buscando.
        </p>
        <a class="add btn btn-primary mb-2" routerLink="/escritorio">
            Volver a la pagina de inicio
        </a>
    </div>
</div>