<div class="hold-transition login-page text-center">
    <div class="overlay bg-base-primary"></div>
    <div class="login-box">
        <form id="form-login" class="form-login">
            <img class="mb-4" src="/assets/img/logo.png" alt="" width="125" height="125">

            <div class="form-group">
                <label for="email" class="text-white mb-2">Introduzca su dirección de correo electrónico registrado.</label>
                <input type="text" id="email" name="email" class="form-control mb-2" placeholder="Email*" required autofocus
                    data-inputmask="'regex': '^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.-]{4,30}$'"
                    data-mask>
            </div>

            <button (click)="recover()" class="btn btn-lg btn-primary btn-block mb-2" type="submit">Recuperar</button>
            <button routerLink="/login" class="btn btn-lg btn-secondary btn-block" type="submit">Cancelar</button>
        </form>
    </div>
</div>