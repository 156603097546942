<div class="hold-transition login-page text-center">
    <div class="overlay bg-base-primary"></div>
    <div class="login-box">
        <form id="form-login" class="form-login">
            <img class="mb-4" src="/assets/img/logo.png" alt="" width="125" height="125">
            <h1 class="h3 mb-3 font-weight-normal text-white">Por favor, Inicie sesión</h1>
            <label for="username" class="sr-only">
                Nombre de usuario
            </label>
            <input type="text" id="username" name="username" class="form-control" placeholder="Nombre de usuario" required autofocus data-inputmask="'regex': '^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.-]{4,30}$'" data-mask [(ngModel)]="username">
            <label for="password" class="sr-only">Contraseña</label>
            <input type="password" id="password" name="password" class="form-control" placeholder="Contraseña" required [(ngModel)]="password">

            <div class="error-message mb-3 mt-3" *ngIf="error">
                {{error}}
            </div>

            <div class="checkbox mb-3 mt-3">
                <label class="text-white">
                    <input type="checkbox" value="remember-me"> Recuerdame
                </label>
            </div>

            <button (click)="login()" class="btn btn-lg btn-primary btn-block mb-3" type="submit">
                Iniciar sesión
            </button>

            <a class="col-12 text-white" routerLink="/recuperar">
                <strong>¿Contrasena perdida?</strong>
            </a>
        </form>
        <div class="app-title-login">
            <div class="app-title-login-inner">
                <h5>
                    GESC | Software Gestor Escolar & Comercial
                </h5>
                <p>
                    Software suizo | Creado y diseñado por nuestros socios con sede en suiza
                    ® BIMTserver Project Management Software
                </p>
            </div>
        </div>
        <!-- /.app-title-login -->
    </div>
    <!-- /.login-box -->
</div>
<!-- /.login-page -->